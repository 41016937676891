@import 'styles';

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-height: calc(100vh - $header-height - $input-container-height - 32px);
  overflow: auto;
  padding: 16px 24px 0 24px;
}
