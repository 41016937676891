@import 'styles';

.root {
  padding: 16px 24px;
}

.form {
  height: $input-container-height;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 28px;
  border: 1px solid $nj-neutral-300;
  padding: 8px 24px;
  gap: 16px;
  overflow: auto;

  &:focus-within {
    border: 1px solid $nj-accent-100;
  }
}

.textarea {
  height: 100%;
  background: transparent;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  border: none;
  outline: none;
  color: $nj-neutral-300;
  flex-grow: 1;
  resize: none;
  overflow: hidden;

  &:focus {
    color: $nj-neutral-000;
  }
}

.actionButtons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.clearButton,
.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearButton {
  color: $nj-neutral-100;
}

.submitButton {
  color: $nj-accent-100;

  &:disabled {
    color: $nj-neutral-200;
  }
}
