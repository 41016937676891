@import 'styles';

.root {
  display: flex;
  width: 100%;

  &.incoming {
    justify-content: flex-start;

    .container {
      align-items: flex-start;
    }

    .content {
      border-radius: 2px 16px 16px 24px;
      background-color: $nj-blue-50;
      color: $nj-neutral-blk;
    }
  }

  &.outcoming {
    justify-content: flex-end;

    .container {
      align-items: flex-end;
    }

    .content {
      border-radius: 16px 2px 24px 16px;
      background-color: $nj-blue-700;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 50%;
}

.content {
  padding: 8px 24px;
}

.title,
.footer {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $nj-neutral-200;
}

.footer {
  text-transform: uppercase;
  width: 100%;
  text-align: right;
}
