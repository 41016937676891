@import 'styles';

.root {
  margin: 16px 0;
  padding-left: 8px;
}

.legend {
  font-weight: 600;

  &.isRequired {
    color: $nj-red-500;
  }
}

.description {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.score {
  display: flex;
  gap: 8px;
  margin: 8px 0;
}

.radio {
  display: none;
}

.radioLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: $nj-neutral-200;

  &.active {
    background-color: $nj-neutral-100;
    color: $nj-neutral-900;
  }
}
