@import 'styles';

.root {
  height: $header-height;
  border-bottom: 1px solid $nj-primary-blk;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectorWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.startButton {
  padding: 10px 16px;
  background: rgba($nj-accent-100, 0.38);
  border-radius: 20px;
  font-weight: 500;
  color: $nj-neutral-100;
  font-size: 14px;
  line-height: 20px;

  &:disabled {
    color: $nj-neutral-blk;
  }
}
