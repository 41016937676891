// Sizes
$menu-width: 80px;
$feedback-panel-width: 360px;
$header-height: 72px;
$input-container-height: 56px;

// Constants
$base-font-size: 14px;
$base-line-height: 20px;

// Breakpoints
$desktop: 1024px;
