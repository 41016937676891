@import 'styles';

.root {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  flex-grow: 1;
  display: flex;
}

.chatContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
