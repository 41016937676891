@import 'styles';

html,
body {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-stretch: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $nj-neutral-900;
  color: $nj-neutral-100;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

html,
body {
  height: 100vh;
}

body > div {
  height: 100%;
}

* {
  box-sizing: border-box;
}
