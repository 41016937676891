@import 'styles';

.root {
  width: $menu-width;
  border-right: 1px solid $nj-primary-blk;
  height: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.link,
.activeLink {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.activeLink {
  .icon {
    color: $nj-accent-100;
  }
}

.icon {
  width: 48px;
  height: 48px;
  background: $nj-primary-blk;
  border-radius: 24px;
  color: $nj-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
