$nj-neutral-100: #e8eaea;
$nj-neutral-150: #bbbfc0;
$nj-neutral-200: #8e9496;
$nj-neutral-300: #61696c;
$nj-neutral-400: #343e42;
$nj-neutral-900: #11181b;
$nj-neutral-blk: #1d282d;
$nj-neutral-000: #ffffff;
$nj-primary-blk: #343e42;
$nj-accent-100: #0cc5ff;
$nj-accent-200: #548efd;
$nj-orange-400: #ffac5f;
$nj-orange-600: #d99251;
$nj-green-400: #7bcda1;
$nj-random-green: rgba(48, 150, 70, 1);
$nj-random-blue: rgba(70, 85, 218, 1);
$nj-random-purple: rgba(188, 70, 218, 1);
$nj-random-pink: rgba(209, 71, 129, 1);
$nj-blue-700: #055973;
$nj-blue-50: #e7f9ff;
$nj-red-300: #de6b64;
$nj-red-500: #d33a30;
$nj-red-600: #a92e26;
