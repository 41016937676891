@import 'styles';

.root {
  width: $feedback-panel-width;
  border-left: 1px solid $nj-primary-blk;
  height: 100%;
  max-height: calc(100vh - $header-height);
  overflow: auto;
  padding: 24px 16px;
  color: $nj-neutral-200;
  flex-shrink: 0;
}

.title,
.legend {
  font-weight: 700;
}

.title {
  margin-bottom: 8px;
}

.legend {
  margin-bottom: 16px;

  &.isRequired {
    color: $nj-red-500;
  }
}

.note {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
}

.field {
  margin-bottom: 8px;
}

.textarea {
  width: 100%;
  padding: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  border: 1px solid $nj-neutral-300;
  border-radius: 8px;
  outline: none;
  color: $nj-neutral-200;
  resize: none;
  background: transparent;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  padding: 10px 16px;
  color: $nj-accent-100;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
